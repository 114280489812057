import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styles from "./ClientsTable.module.scss";

const ClientsTable = ({ domains }) => {
  return (
    <div className={styles.container}>
      {domains.map(({ name, clients }) => {
        return (
          <div className={styles.domain} key={name}>
            <h2 className={styles.domainName}>{name}</h2>
            <ol className={styles.clientsList}>
              {clients.map(({ name: clientName, slug }) => {
                return (
                  <li key={clientName} className={styles.client}>
                    {slug && <Link to={`${slug}/`}>{clientName}</Link>}
                    {!slug && clientName}
                  </li>
                );
              })}
            </ol>
          </div>
        );
      })}
    </div>
  );
};

ClientsTable.propTypes = {
  domains: PropTypes.array.isRequired,
};

export default ClientsTable;
