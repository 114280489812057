import React, { useState } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { get, groupBy, mapValues, take, slice } from "lodash";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProjectTile from "../components/ProjectTile";
import withGraphQLErrors from "../hocs/withGraphQLErrors";
import ContentLayout from "../primitives/ContentLayout";
import SectionLayout from "../primitives/SectionLayout";
import ClientsTable from "../components/ClientsTable";
import styles from "../styles/projects.module.scss";

export const query = graphql`
  query ProjectsPageQuery {
    projects: allSanityProject {
      nodes {
        slug {
          current
        }
        domains {
          title
        }
        card {
          clients {
            title
          }
        }
      }
    }
    grid: sanityProjectsGrid(_id: { eq: "projectsGrid" }) {
      projects {
        ... on SanityProject {
          thumbImage {
            asset {
              _id
            }
            crop {
              bottom
              left
              right
              top
            }
            hotspot {
              height
              x
              width
              y
            }
          }
          subtitle
          title
          slug {
            current
          }
        }
        ... on SanityConfidentialProject {
          thumbImage {
            asset {
              _id
            }
            crop {
              bottom
              left
              right
              top
            }
            hotspot {
              height
              x
              width
              y
            }
          }
          title
        }
      }
    }
    clients: allSanityClient {
      nodes {
        title
        domains {
          title
        }
      }
    }
    page: sanityRoute(slug: { current: { eq: "projets" } }) {
      page {
        title
        subtitle
        _rawContent(resolveReferences: { maxDepth: 10 })
        meta {
          metaTitle
          metaDescription
        }
        openGraphImage {
          asset {
            _id
          }
        }
      }
    }
  }
`;

const ProjectsPage = ({ data }) => {
  const [isAllProjectsVisible, setIsAllProjectsVisible] = useState(false);
  const { grid = {}, projects = {}, clients = {}, page = {} } = data || {};
  const { page: pageContent = {} } = page;
  const projectWithDomainAndClient = projects.nodes.filter(
    project =>
      get(project, "domains.length", 0) > 0 &&
      get(project, "card.clients.length", 0) > 0
  );
  const projectsByDomains = groupBy(
    projectWithDomainAndClient,
    project => project.domains[0].title
  );
  const projectsByDomainsAndClients = mapValues(projectsByDomains, projects => {
    return groupBy(projects, project => project.card.clients[0].title);
  });
  const projectsSlugByDomainsAndClients = mapValues(
    projectsByDomainsAndClients,
    clients => {
      return mapValues(clients, projects => projects[0].slug.current);
    }
  );

  clients.nodes.forEach(client => {
    client.domains.forEach(domain => {
      projectsSlugByDomainsAndClients[domain.title] = {
        ...projectsSlugByDomainsAndClients[domain.title],
        [client.title]: projectsSlugByDomainsAndClients[domain.title][
          client.title
        ]
          ? projectsSlugByDomainsAndClients[domain.title][client.title]
          : null,
      };
    });
  });

  const sortByName = ({ name: nameA }, { name: nameB }) =>
    new Intl.Collator("fr").compare(nameA, nameB);
  const sortedDomains = Object.entries(projectsSlugByDomainsAndClients)
    .map(([domain, clients]) => {
      return {
        name: domain,
        clients: Object.entries(clients)
          .map(([client, slug]) => ({
            name: client,
            slug,
          }))
          .sort(sortByName),
      };
    })
    .sort(sortByName);

  const firstElevenProjects = grid.projects ? take(grid.projects, 11) : [];
  const secondElevenProjects =
    grid.projects && grid.projects.length > 11
      ? slice(grid.projects, 11, Math.min(26, grid.projects.length))
      : [];

  return (
    <Layout inverse>
      <Helmet>
        <style>{"body { background-color: var(--color-bg-dark); }"}</style>
      </Helmet>
      <Seo
        title={
          get(page, "page.meta.metaTitle", "") || get(page, "page.title", "")
        }
        description={get(page, "page.meta.metaDescription", "")}
        image={get(page, "page.openGraphImage", null)}
      />

      <ContentLayout>
        <section>
          <header>
            <h1 className={styles.pageTitle}>{pageContent.title}</h1>
            <p className={styles.pageLead}>{pageContent.subtitle}</p>
          </header>

          <div className={styles.gridContainer}>
            {firstElevenProjects.map(
              ({ title, subtitle, slug, thumbImage }, i) => {
                return (
                  <div
                    className={styles[`gridItem${i + 1}`]}
                    key={slug ? slug.current : title}
                  >
                    <ProjectTile
                      slug={slug ? `/projets/${slug.current}/` : null}
                      title={title}
                      subtitle={subtitle}
                      thumbnail={thumbImage}
                    />
                  </div>
                );
              }
            )}
          </div>

          {isAllProjectsVisible && (
            <div className={styles.gridContainer}>
              {secondElevenProjects.map(
                ({ title, subtitle, slug, thumbImage }, i) => {
                  return (
                    <div
                      className={styles[`gridItem${i + 1}`]}
                      key={slug ? slug.current : title}
                    >
                      <ProjectTile
                        slug={slug ? `/projets/${slug.current}/` : null}
                        title={title}
                        subtitle={subtitle}
                        thumbnail={thumbImage}
                      />
                    </div>
                  );
                }
              )}
            </div>
          )}

          <div className={styles.plusButtonContainer}>
            <button
              className={styles.plusButton}
              type="button"
              onClick={() => setIsAllProjectsVisible(!isAllProjectsVisible)}
            >
              {!isAllProjectsVisible ? "+" : "-"}
            </button>
          </div>
        </section>
      </ContentLayout>

      <SectionLayout
        backgroundColor={{ value: "var(--color-bg-dark)" }}
        id="clients"
      >
        <ContentLayout>
          <section>
            <header>
              <h1 className={styles.pageTitle}>Nos clients</h1>
              <p className={styles.pageLeadSecondary}>
                Depuis 20 ans, nous réalisons les projets des domaines experts
              </p>
            </header>
            <ClientsTable domains={sortedDomains} />
          </section>
        </ContentLayout>
      </SectionLayout>
    </Layout>
  );
};

export default withGraphQLErrors()(ProjectsPage);
